export const ENGLISH_TRANSLATIONS = {
  text: {
    i_want: 'I want',
    about_your_new: 'About your new',
    you_also_get: 'You´ll also get',
    back: 'Back',
    skip: 'Skip this step',
    itemscount: 'items in My Kitchen',
    removeitem: 'remove item',
    send: 'send',
    enteremail: 'Enter email',
    add_to_my_kitchen: 'Add to My Kitchen',
    dont_have_time_right_now: 'Don´t have time right now?',
    remind_me_later: 'Remind me later',
    added_to_your_wishlist: 'added to your wish list.',
    added_to_my_kitchen: 'added to My Kitchen.',
    here_are_all_the_appliances_that_you_want_in_your_next_dream_kithcen: 'Here are all the appliances that you want in your next dream kitchen',
    unique_link_for_your_kitchen: 'Unique link for your kitchen',
    send_your_kitchen_to_your_email: 'Send your kitchen to your email',
    pdf_successfully_sent_to_your_email: 'PDF successfully sent to your email!',
    copy: 'Copy',
    download_package_as_pdf: 'Download Package as PDF',
    print: 'Print',
    my_kitchen: 'My Kitchen',
    insert_your_email: 'Insert your email',
    send_reminder: 'Send reminder',
    email_required: 'Email required',
    valid_email_required: 'Valid email required',
    reminder_is_sent_to_your_email: 'Reminder is sent to your email!',
    close: 'Close',
    need_a_different_size: 'Need a different size?',
    download_detail_product_pdf: 'Download detailed product PDF',
    bundle: 'Bundle',
    or_do_you_need_the_little_sister_with_most_of_above_functions: 'Or do you only need the little sister with most of above functions but not as sophisticated?',
    features: 'features',
    continue_to_next_step: 'Continue to next step',
    your_kitchen_is_empty: 'Your kitchen is empty. Add some products.',
    downloading: 'Downloading',
    get_back_to_where_you_started: 'Get back where you started Kitchen Guide',
    info_about_concept: 'Info about the studioLine concept',
    accept: 'Accept',
    decline: 'Decline',
    please_accept: 'Please accept cookie consent to play this video',
    cookies_text: 'If you click on "Accept", you allow us to record your usage behavior on this website. This allows us to improve our website and personalize advertising for you. If you click on "decline", we will only use session cookies to improve user-friendliness and measure statistical reach.',
    start_over: 'Start over'
  }
}
