const updateLanguage = (state, language) => {
  state.language = language;
};

const updateLocale = (state) => {
  state.locale = Object.keys(state.language).toString();
};

const updateReturnUrl = (state, returnUrl) => {
  state.returnUrl = returnUrl;
};

const toggleCookieConsent = (state, open) => {
  state.showCookieConsent = open;
};

const setCookieConsent = (state, consent) => {
  state.cookieConsent = consent;
};

const updatePageInfo = (state, pageInfo) => {
  state.pageInfo = pageInfo;
};

const updateCategory = (state, categoryinfo) => {
  state.categoryinfo = categoryinfo;
};

const updateCategorySlug = (state, categorySlug) => {
  state.categorySlug = categorySlug;
};

const updateCategoryProducts = (state, products) => {
  state.categoryinfo.products = products;
};

const updateKitchen = (state, kitchenInfo) => {
  state.kitchenInfo = kitchenInfo;
  state.kitchenId = kitchenInfo.data.id;
  state.kitchenSlug = kitchenInfo.data.slug_hash;
};

const updateDoneCategories = (state, kitchenInfo) => {
  const productIdArr = [];
  Object.values(kitchenInfo.response.data.data.category_products).forEach(
    (value) => {
      if (value.parent_id === 0) {
        productIdArr.push(value.id);
      } else {
        productIdArr.push(value.parent_id);
      }
    }
  );
  Object.values(state.addedProducts).forEach((cartEl) => {
    for (let i = 0; i < kitchenInfo.productIds.length; i++) {
      for (let a = 0; a < cartEl.length; a++) {
        if (cartEl[a].id === kitchenInfo.productIds[i]) {
          const mySlug = kitchenInfo.slug;
          state.addedProducts[mySlug] = [];
        }
      }
    }
  });
  state.doneCategories = productIdArr;
};

const updateCategoriesList = (state, categorieslist) => {
  state.categorieslist = categorieslist;
};

const updateCategoryId = (state, categoryId) => {
  state.categoryId = categoryId;
};

const updateStep = (state, stepInfo) => {
  state.stepInfo = stepInfo;
};
const updateStepId = (state, stepId) => {
  state.stepId = stepId;
};
const updateChoices = (state, chosenFeatures) => {
  state.chosenFeatures = chosenFeatures;
};
const addChosenFeatureToCoices = (state, featureId) => {
  /* if (!state.chosenFeatures.includes(featureId)) { */
  state.chosenFeatures.push(featureId);
  /* } */
};
const removeChosenFeatureFromChoices = (state, featureId) => {
  /* if (state.chosenFeatures.includes(featureId)) { */
  state.chosenFeatures.pop(featureId);
  /* } */
};

const chosenFeaturesType = (state, data) => {
  state.chosenFeaturesType = data;
};

const removeChosenFeaturesFromChoices = (state, features) => {
  features.forEach((el) => {
    state.chosenFeatures.pop(el);
  });
};
const addChosenFeaturesToChoices = (state, features) => {
  features.forEach((el) => {
    state.chosenFeatures.push(el);
  });
};
const createHistoryObject = (state) => {
  if (state.categorieslist && Object.keys(state.stepHistory).length === 0) {
    state.categorieslist.forEach((category) => {
      state.stepHistory[category.slug] = [];
      if (category.child_categories) {
        category.child_categories.forEach((childCategory) => {
          state.stepHistory[category.slug][childCategory.slug] = [];
        });
      }
    });
  }
};
const updateStepCounter = (state, value) => {
  if (value === "increase") {
    state.stepCounter += 1;
  } else if (value === "decrease") {
    state.stepCounter -= 1;
  }
};

const createProductObject = (state) => {
  if (state.categorieslist && Object.keys(state.addedProducts).length === 0) {
    state.categorieslist.forEach((category) => {
      state.addedProducts[category.slug] = [];
      if (category.child_categories) {
        category.child_categories.forEach((child) => {
          state.addedProducts[child.slug] = [];
        });
      }
    });
  }
};

const updateAddedProducts = (state, payload) => {
  if (
    (state.addedProducts[payload.parentSlug] ||
      state.addedProducts[payload.slug]) &&
    payload.bundleproduct !== 1
  ) {
    state.addedProducts[payload.parentSlug] = [];
    state.addedProducts[payload.slug] = [];
  }
  if (!payload.parentSlug) {
    if (!state.addedProducts[payload.slug]) {
      state.addedProducts[payload.slug] = [];
    }
    if (
      state.addedProducts[payload.slug].filter((e) => e.id === payload.id)
        .length === 0
    ) {
      state.addedProducts[payload.slug].push({
        id: payload.id,
        size: payload.size,
      });
    }
  } else {
    if (!state.addedProducts[payload.slug]) {
      state.addedProducts[payload.parentSlug] = [];
      state.addedProducts[payload.slug] = [];
    }
    if (
      state.addedProducts[payload.slug].filter((e) => e.id === payload.id)
        .length === 0
    ) {
      state.addedProducts[payload.slug].push({
        id: payload.id,
        size: payload.size,
      });
    }
  }
};

const clearAddedProductsInCategory = (state, slug) => {
  state.addedProducts[slug] = [];
};

const updateHistory = () => {
  /* if (!payload.parentSlug) {
    if (!state.stepHistory[payload.slug].includes(payload.id)) {
      state.stepHistory[payload.slug].push(payload.id)
    }
  } else {
    if (!state.stepHistory[payload.parentSlug][payload.slug].includes(payload.id)) {
      state.stepHistory[payload.parentSlug][payload.slug].push(payload.id)
    }
  } */
};

const removeFromHistory = (state, payload) => {
  if (
    state.stepHistory[payload.slug] &&
    state.stepHistory[payload.slug][
      state.stepHistory[payload.slug].length - 1
    ] === payload.id
  ) {
    state.stepHistory[payload.slug].pop();
  }
};

const clearChoices = (state) => {
  state.chosenFeatures = [];
};

const clearDesignChoices = (state) => {
  state.chosenDesignFeatures = [];
};

const clearSizeRestrictions = (state) => {
  state.sizeRestrictions = [];
};

const updateSizeRestrictions = (state, size) => {
  // state.sizeRestrictions = sizeRestrictions
  if (state.sizeRestrictions.includes(size)) {
    state.sizeRestrictions.pop(size);
  } else {
    state.sizeRestrictions.push(size);
  }
};

const addSizeRestrictions = (state, sizes) => {
  sizes.forEach((value) => {
    if (!state.sizeRestrictions.includes(value)) {
      state.sizeRestrictions = [sizes[0]];
      // state.sizeRestrictions.push(value)
    }
  });
};
const removeSizeRestrictions = (state, sizes) => {
  sizes.forEach((value) => {
    if (state.sizeRestrictions.includes(value)) {
      state.sizeRestrictions.pop(value);
    }
  });
};
const choices = (state, featureId) => {
  if (!state.chosenFeatures.includes(featureId) && !Array.isArray(featureId)) {
    state.chosenFeatures.push(featureId);
  } else if (Array.isArray(featureId)) {
    state.chosenFeatures = featureId;
  } else {
    state.chosenFeatures.splice(state.chosenFeatures.indexOf(featureId), 1);
  }
  if (Object.keys(state.temporaryChosenFeatures).length < 1) {
    state.temporaryChosenFeatures = state.chosenFeatures;
  }
};

const designChoices = (state, featureId) => {
  if (
    !state.chosenDesignFeatures.includes(featureId) &&
    !Array.isArray(featureId)
  ) {
    state.chosenDesignFeatures.push(featureId);
  } else if (Array.isArray(featureId)) {
    state.chosenDesignFeatures = featureId;
  } else {
    state.chosenDesignFeatures.splice(
      state.chosenDesignFeatures.indexOf(featureId),
      1
    );
  }
};
const categoryDone = (state, catInfo) => {
  if (catInfo.parentCatId) {
    if (!state.doneCategories.includes(catInfo.parentCatId)) {
      state.doneCategories.push(catInfo.parentCatId);
    }
  } else {
    if (!state.doneCategories.includes(catInfo.catId)) {
      state.doneCategories.push(catInfo.catId);
    }
  }
};

const addProduct = (state, productId) => {
  state.addedProduct = productId;
};
const clearAddedProduct = (state) => {
  state.addedProduct = null;
};
const updateAddOnChoices = (state, addOnFeatures) => {
  state.addOnFeatures = addOnFeatures;
};
const clearAddOnFeatures = (state) => {
  state.addOnFeatures = [];
};
const addAddOnFeatureToChoices = (state, featureId) => {
  if (!state.addOnFeatures.includes(featureId)) {
    state.addOnFeatures.push(featureId);
  }
};
const removeAddOnFeatureFromChoices = (state, featureId) => {
  if (state.addOnFeatures.includes(featureId)) {
    state.addOnFeatures.pop(featureId);
  }
};

const updateChosenFeaturesObject = (state, data) => {
  state.chosenFeaturesObject = data;
};

const clearTemporaryChosenFeatures = (state) => {
  state.temporaryChosenFeatures = [];
};

const clearCategoryInfo = (state) => {
  state.categoryinfo = [];
};

export default {
  createHistoryObject,
  createProductObject,
  updateAddedProducts,
  updateLanguage,
  updateLocale,
  updatePageInfo,
  updateCategory,
  updateCategorySlug,
  updateCategoryProducts,
  updateKitchen,
  updateCategoriesList,
  updateCategoryId,
  updateStep,
  updateStepId,
  updateStepCounter,
  updateChoices,
  updateHistory,
  removeFromHistory,
  choices,
  designChoices,
  clearChoices,
  clearDesignChoices,
  categoryDone,
  addProduct,
  clearAddedProduct,
  removeSizeRestrictions,
  addSizeRestrictions,
  clearSizeRestrictions,
  removeChosenFeatureFromChoices,
  removeChosenFeaturesFromChoices,
  addChosenFeaturesToChoices,
  addChosenFeatureToCoices,
  chosenFeaturesType,
  updateSizeRestrictions,
  addAddOnFeatureToChoices,
  removeAddOnFeatureFromChoices,
  updateAddOnChoices,
  clearAddOnFeatures,
  clearAddedProductsInCategory,
  updateChosenFeaturesObject,
  updateReturnUrl,
  updateDoneCategories,
  clearCategoryInfo,
  clearTemporaryChosenFeatures,
  setCookieConsent,
  toggleCookieConsent,
};
