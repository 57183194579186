<template>
  <div class="c-results c-assortment">
    <!-- Assortment Template Start-->
    <div
      v-if="assortmentProducts"
      class="c-assortment__list"
    >
      <!-- <div v-if="bestProductsMatch">{{bestProductsMatch}}</div> --> <!-- exact match and size -->
      <!-- <div v-if="alternativeProducts">{{alternativeProducts}}</div> -->
      <!-- Same Size But different features -->
      <!-- <div v-if="alternativeSizeProducts">{{alternativeSizeProducts}}</div> -->
      <!-- Different size products -->
      <div
        v-for="(product) in assortmentProducts"
        :key="product"
        data-aos="fade-right"
      >
        <div
          v-if="product.is_bundle === '1'"
          :lang="locale"
          class="c-result__product-bundle"
        >
          <v-textmedia
            class="c-bundle__textmedia"
            :images="product.media && product.media.length ? Object.values(product.media) : []"
            :heading="product.title"
            :content="product.text"
            imgPosition='right'
            imgSize='big'
          />
          <div class="c-result__product-bundle__products">
            <v-child-product
              :id="product.bundle_products[0].id"
              :title="product.bundle_products[0].title"
              :description="product.bundle_products[0].text"
              :size="product.bundle_products[0].size"
              :sku="product.bundle_products[0].sku"
              addToKitchenButton="true"
              :product="product.bundle_products[0]"
            />
            <v-icon
              class="c-result__product-bundle-plus"
              type="plus"
              size="40"
            />
            <v-child-product
              :id="product.bundle_products[1].id"
              :title="product.bundle_products[1].title"
              :description="product.bundle_products[1].text"
              :size="product.bundle_products[1].size"
              :sku="product.bundle_products[1].sku"
              addToKitchenButton="true"
              :product="product.bundle_products[1]"
            />
          </div>
          <div
            class="c-step__button-wrapper"
            v-if="translations[locale].text.add_to_my_kitchen"
            data-aos="fade-right"
          >
            <v-button
              variation="primary"
              :text="translations[locale].text.add_to_my_kitchen"
              @click="addMultipleProducts([product.bundle_products[0], product.bundle_products[1]]); saveHistory(stepInfo.id, stepInfo.link_url.replace('dynamic-category', slug));"
            />
          </div>
        </div>
        <!-- If More than one Product accociation -> Then show Bundle product -->
        <div v-if="this.stepInfo.show_addon_products === 1 && !addOnFeatures.length">
          <div
            class="c-result__product-bundle"
            :lang="locale"
            v-if="product.accociations.length"
          >
            <div class="c-result__product-bundle__products">
              <v-child-product
                :id="product.id"
                addToKitchenButton="true"
                :title="product.title"
                :description="product.text"
                :size="product.size"
                :sku="product.sku"
                :images="product.media && product.media.length ? Object.values(product.media) : []"
                :sizeOnImage="true"
                :product="product"
              />
              <v-icon
                class="c-result__product-bundle-plus"
                type="plus"
                size="40"
              />
              <v-child-product
                :id="product.accociations[0].id"
                :title="product.accociations[0].title"
                :description="product.accociations[0].text"
                :size="product.accociations[0].size"
                :sku="product.accociations[0].sku"
                :images="product.accociations[0].media && product.accociations[0].media.length ? Object.values(product.accociations[0].media) : []"
                addToKitchenButton="true"
                :sizeOnImage="true"
                :product="product.accociations[0]"
              />
            </div>
          </div>
          <div
            class="c-step__button-wrapper"
            v-if="translations[locale].text.add_to_my_kitchen && product.accociations.length"
            data-aos="fade-right"
          >
            <v-button
              variation="primary"
              :text="translations[locale].text.add_to_my_kitchen"
              @click="addMultipleProducts([product, product.accociations[0]]); saveHistory(stepInfo.id, stepInfo.link_url.replace('dynamic-category', slug));"
            />
          </div>
        </div>
        <div v-if="product.accociations.length && addOnFeatures.length">
          <div
            class="c-result__product-bundle"
            :lang="locale"
            v-if="product.accociations.length"
          >
            <div class="c-result__product-bundle__products">
              <v-child-product
                :id="product.id"
                addToKitchenButton="true"
                :title="product.title"
                :description="product.text"
                :size="product.size"
                :sku="product.sku"
                :images="product.media && product.media.length ? Object.values(product.media) : []"
                :sizeOnImage="true"
                :product="product"
              />
              <v-icon
                class="c-result__product-bundle-plus"
                type="plus"
                size="40"
              />
              <v-child-product
                v-for="accociatedProduct in findMatchingAccociatedProduct(product.accociations, addOnFeatures)"
                :key="accociatedProduct"
                :id="accociatedProduct.id"
                :title="accociatedProduct.title"
                :description="accociatedProduct.text"
                :size="accociatedProduct.size"
                :sku="accociatedProduct.sku"
                :images="accociatedProduct.media && accociatedProduct.media.length ? Object.values(accociatedProduct.media) : []"
                addToKitchenButton="true"
                :sizeOnImage="true"
                :product="accociatedProduct"
              />
            </div>
          </div>
          <div
            class="c-step__button-wrapper"
            v-if="translations[locale].text.add_to_my_kitchen"
            data-aos="fade-right"
          >
            <v-button
              variation="primary"
              :text="translations[locale].text.add_to_my_kitchen"
              @click="addMultipleProducts([product, product.accociations[0]]); saveHistory(stepInfo.id, stepInfo.link_url.replace('dynamic-category', slug));"
            />
          </div>
        </div>
        <!-- If NO Product accociation -> Then show Single Product -->
        <div
          v-if="!product.children.length && product.accociations.length !== 1 && product.hide_in_assortment === 0"
          class="c-assortment__result"
        >
          <v-product
            v-if="product"
            class="c-result__product"
            :title="product.title"
            :description="product.text"
            :size="product.size"
            :sku="product.sku"
            :images="product.media && product.media.length ? Object.values(product.media) : []"
            :full="assortmentProducts.length === 1 ? 'true' : 'false'"
            :sizeOnImage="true"
            addToKitchenButton="true"
            :id="product.id"
            :product="product"
          />
          <div
            class="c-step__button-wrapper"
            v-if="translations[locale].text.add_to_my_kitchen"
          >
            <v-button
              variation="primary"
              :text="translations[locale].text.add_to_my_kitchen"
              @click="saveHistory(stepInfo.id, stepInfo.link_url.replace('dynamic-category', slug)); addMultipleProducts([product]);"
            />
          </div>
        </div>
        <div v-else>
          <div v-if="!addOnFeatures.length && !this.stepInfo.show_addon_products">
            <v-product
              v-if="product"
              class="c-result__product"
              :title="product.title"
              :description="product.text"
              :size="product.size"
              :sku="product.sku"
              :images="product.media && product.media.length ? Object.values(product.media) : []"
              :full="assortmentProducts.length === 1 ? 'true' : 'false'"
              :sizeOnImage="true"
              addToKitchenButton="true"
              :id="product.id"
              :product="product"
            />
          </div>
        </div>
        <!-- If More than one Product accociation -> Then show Bundle product -->
        <!-- <div v-if="product.alternatives.length">
            <div class="c-assortment__alternatives-list"  v-if="product.alternatives.length > 0">
                <h3 class="c-feature-heading" v-if="!route === '/en/category/ovens/36/' || !route === '/se/category/ovens/36/'">
                    {{translations[locale].text.need_a_different_size}}
                </h3>
                <h3 class="c-feature-heading" v-else>
                    {{translations[locale].text.or_do_you_need_the_little_sister_with_most_of_above_functions}}
                </h3>
                <div class="c-alternatives__section" v-for="alternativeProduct in product.alternatives" :key="alternativeProduct">
                    <div class="c-result__product-bundle" :lang="locale" v-if="alternativeProduct.children && alternativeProduct.children.length">
                        <v-textmedia
                            class="c-bundle__textmedia"
                            :images="alternativeProduct.media && alternativeProduct.media.length ? Object.values(alternativeProduct.media) : []"
                            :heading="alternativeProduct.title[locale]"
                            :content="alternativeProduct.text[locale]"
                            imgPosition='right'
                            imgSize='big'
                        />
                        <div class="c-result__product-bundle__products">
                            <v-child-product :id="alternativeProduct.children[0].id"
                                :title="alternativeProduct.children[0].title[locale]"
                                :description="alternativeProduct.children[0].text[locale]"
                                :size="alternativeProduct.children[0].size"
                                :sku="alternativeProduct.children[0].sku"
                                addToKitchenButton="true"
                                :product="alternativeProduct.children[0]"
                            />
                            <v-icon class="c-result__product-bundle-plus" type="plus" size="40" />
                            <v-child-product :id="alternativeProduct.children[1].id"
                                :title="alternativeProduct.children[1].title[locale]"
                                :description="alternativeProduct.children[1].text[locale]"
                                :size="alternativeProduct.children[1].size"
                                :sku="alternativeProduct.children[1].sku"
                                addToKitchenButton="true"
                                :product="product.children[1]"
                            />
                        </div>
                        <div class="c-step__button-wrapper" v-if="stepInfo.link_text[locale]">
                            <v-button variation="primary" :text="stepInfo.link_text[locale]" @click="saveToKitchen(alternativeProduct.id, alternativeProduct.size); saveHistory(stepId, stepInfo.link_url); addMultipleProducts(alternativeProduct); $router.push({path:`/${locale}${stepInfo.link_url}`})" />
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
      </div>
    </div>
    <div
      v-for="alternativeProductParent in assortmentProducts"
      :key="alternativeProductParent"
    >
      <div
        class="c-assortment__alternatives-list"
        v-if="alternativeProductParent.alternatives.length > 0"
      >
        <h3
          class="c-feature-heading"
          data-aos="fade-right"
        >
          {{ translations[locale].text.or_do_you_need_the_little_sister_with_most_of_above_functions
          }}
        </h3>
        <div
          class="c-alternatives__section"
          v-for="(alternativeProduct, index) in alternativeProductParent.alternatives"
          :key="alternativeProduct"
        >
          <div v-if="this.stepInfo.show_addon_products === 1 && !addOnFeatures.length">
            <div
              class="c-result__product-bundle"
              :lang="locale"
              v-if="alternativeProduct.accociations.length"
            >
              <div class="c-result__product-bundle__products">
                <v-child-product
                  data-aos="fade-up"
                  :data-aos-delay="(index + 1) * 100"
                  :id="alternativeProduct.id"
                  addToKitchenButton="true"
                  :title="alternativeProduct.title"
                  :description="alternativeProduct.text"
                  :size="alternativeProduct.size"
                  :sku="alternativeProduct.sku"
                  :images="alternativeProduct.media && alternativeProduct.media.length ? Object.values(alternativeProduct.media) : []"
                  :sizeOnImage="true"
                  :product="alternativeProduct"
                />
                <v-icon
                  class="c-result__product-bundle-plus"
                  type="plus"
                  size="40"
                />
                <v-child-product
                  data-aos="fade-up"
                  :data-aos-delay="(index + 1) * 100"
                  :id="alternativeProduct.accociations[0].id"
                  :title="alternativeProduct.accociations[0].title"
                  :description="alternativeProduct.accociations[0].text"
                  :size="alternativeProduct.accociations[0].size"
                  :sku="alternativeProduct.accociations[0].sku"
                  :images="alternativeProduct.accociations[0].media && alternativeProduct.accociations[0].media.length ? Object.values(alternativeProduct.accociations[0].media) : []"
                  addToKitchenButton="true"
                  :sizeOnImage="true"
                  :product="alternativeProduct.accociations[0]"
                />
              </div>
            </div>
            <div
              class="c-step__button-wrapper"
              v-if="translations[locale].text.add_to_my_kitchen"
              data-aos="fade-right"
            >
              <v-button
                variation="primary"
                :text="translations[locale].text.add_to_my_kitchen"
                @click="addMultipleProducts([alternativeProduct, alternativeProduct.accociations[0]]); saveHistory(stepInfo.id, stepInfo.link_url.replace('dynamic-category', slug));"
              />
            </div>
          </div>
          <div v-else>
            <v-product
              v-if="alternativeProduct"
              class="c-result__product"
              :title="alternativeProduct.title"
              :description="alternativeProduct.text"
              :size="alternativeProduct.size"
              :sku="alternativeProduct.sku"
              :images="alternativeProduct.media && alternativeProduct.media.length ? Object.values(alternativeProduct.media) : []"
              :full="alternativeProduct.length === 1 ? 'true' : 'false'"
              :sizeOnImage="true"
            />
            <div
              class="c-step__button-wrapper"
              v-if="translations[locale].text.add_to_my_kitchen"
            >
              <v-button
                variation="primary"
                :text="translations[locale].text.add_to_my_kitchen"
                @click="addMultipleProducts([alternativeProduct]); saveHistory(stepId, stepInfo.link_url.replace('dynamic-category', slug));"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="c-assortment__alternatives-list"
      v-if="alternativeProductsAssortment.length > 0"
    >
      <h3 class="c-feature-heading">
        {{ translations[locale].text.need_a_different_size }}
      </h3>
      <div
        class="c-alternatives__section"
        v-for="alternativeProduct in alternativeProductsAssortment"
        :key="alternativeProduct"
        data-aos="fade-right"
      >
        <v-product
          v-if="alternativeProduct"
          class="c-result__product"
          :title="alternativeProduct.title"
          :description="alternativeProduct.text"
          :size="alternativeProduct.size"
          :sku="alternativeProduct.sku"
          :images="alternativeProduct.media && alternativeProduct.media.length ? Object.values(alternativeProduct.media) : []"
          :full="alternativeProduct.length === 1 ? 'true' : 'false'"
          :sizeOnImage="true"
          addToKitchenButton="true"
        />
        <div
          class="c-step__button-wrapper"
          v-if="translations[locale].text.add_to_my_kitchen"
        >
          <v-button
            variation="primary"
            :text="translations[locale].text.add_to_my_kitchen"
            @click="saveHistory(stepId, stepInfo.link_url.replace('dynamic-category', slug)); addMultipleProducts([alternativeProduct]);"
          />
        </div>
      </div>
    </div>
    <!-- Assortment Template End-->
  </div>
</template>

<script>
import Button from '@/components/Button/Button.vue'
import Icon from '@/components/Icon/Icon.vue'
import Product from '@/components/Product/Product.vue'
import ChildProduct from '@/components/Product/ChildProduct/ChildProduct.vue'
import TextMedia from '@/components/TextMedia/TextMedia.vue'
import axios from 'axios'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { ENGLISH_TRANSLATIONS } from '@/translations/en'
import { SWEDISH_TRANSLATIONS } from '@/translations/se'
import { DANISH_TRANSLATIONS } from '@/translations/dk'
import { NORWEGIAN_TRANSLATIONS } from '@/translations/no'
import { FINNISH_TRANSLATIONS } from '@/translations/fi'

const translations = {
  en: ENGLISH_TRANSLATIONS,
  se: SWEDISH_TRANSLATIONS,
  dk: DANISH_TRANSLATIONS,
  no: NORWEGIAN_TRANSLATIONS,
  fi: FINNISH_TRANSLATIONS
}
export default {
  Name: 'Assortment',
  components: {
    'v-button': Button,
    'v-icon': Icon,
    'v-product': Product,
    'v-textmedia': TextMedia,
    'v-child-product': ChildProduct
  },
  props: {
    assortmentProducts: Array,
    alternativeProductsAssortment: Array
  },
  data () {
    return {
      route: this.$route.path,
      locale: this.$route.params.lang,
      slug: this.$route.params.slug,
      translations,
      bestProductsMatch: false,
      alternativeProducts: false,
      alternativeSizeProducts: false
    }
  },
  computed: {
    ...mapGetters([
      'stepInfo',
      'categoryinfo',
      'categorieslist',
      'apiUrl',
      'apiHeader',
      'token',
      'stepHistory',
      'chosenFeatures',
      'addedProduct',
      'addedProducts',
      'kitchenId',
      'stepCounter',
      'kitchenInfo',
      'addOnFeatures'
    ])
  },
  methods: {
    ...mapActions(['loadStep']),
    ...mapMutations([
      'updateHistory',
      'createHistoryObject',
      'removeFromHistory',
      'addProduct',
      'choices',
      'updateChoices',
      'updateKitchen',
      'clearChoices',
      'updateAddedProducts',
      'createProductObject',
      'clearAddedProduct',
      'updateStepCounter',
      'clearAddedProductsInCategory',
      'categoryDone'
    ]),
    saveHistory: async function (stepId, url) {
      this.createHistoryObject()
      const parentCategorySlug = this.categorieslist.find(category => category.id === this.categoryinfo.parent_id)
      const payload = {
        id: stepId,
        slug: this.slug,
        parentSlug: parentCategorySlug ? parentCategorySlug.slug : null
      }
      this.updateHistory(payload)
      this.$router.push({ path: `/${this.locale}${url}` })
      this.updateStepCounter('increase')
    },
    addMultipleProducts: async function (products) {
      if (this.addedProducts[this.slug] && this.addedProducts[this.slug].length > 0) {
        this.clearAddedProductsInCategory(this.slug)
        products.forEach(e => {
          this.addProduct(products)
          const parentCategorySlug = this.categorieslist.find(category => category.id === e.category_id)
          const payload = {
            id: e.id,
            size: e.size,
            slug: this.slug,
            parentSlug: parentCategorySlug ? parentCategorySlug.slug : null,
            bundleproduct: 1
          }
          this.updateAddedProducts(payload)
        }
        )
      } else {
        products.forEach(e => {
          this.addProduct(products)
          const parentCategorySlug = this.categorieslist.find(category => category.id === e.category_id)
          const payload = {
            id: e.id,
            size: e.size,
            slug: this.slug,
            parentSlug: parentCategorySlug ? parentCategorySlug.slug : null,
            bundleproduct: 1
          }
          this.updateAddedProducts(payload)
        }
        )
      }
      this.saveMultipleProductsToKitchen()
    },
    saveMultipleProductsToKitchen: async function () {
      const productIds = Object.values(this.addedProducts).flat(1)
      const onlyProductIds = productIds.map(a => a.id)
      await axios.post(`${this.apiUrl}/api/kitchen/products`,
        {
          ...(this.kitchenId ? { id: this.kitchenId } : {}),
          product_ids: onlyProductIds,
          lang: this.locale
        },
        this.apiHeader
      ).then((response) => {
        this.updateKitchen(response.data)
        this.categoryDone({
          catId: this.categoryinfo.id,
          parentCatId: this.categoryinfo.parent_id
        })
      })
    },
    saveToKitchen: async function (productId, productSize) {
      this.createProductObject()
      const parentCategorySlug = this.categorieslist.find(category => category.id === this.categoryinfo.parent_id)
      const payload = {
        id: productId,
        size: productSize,
        slug: this.slug,
        parentSlug: parentCategorySlug ? parentCategorySlug.slug : null
      }
      this.updateAddedProducts(payload)
      const productIds = Object.values(this.addedProducts).flat(1)
      const onlyProductIds = productIds.map(a => a.id)
      await axios.post(`${this.apiUrl}/api/kitchen/products`,
        {
          ...(this.kitchenId ? { id: this.kitchenId } : {}),
          product_ids: onlyProductIds,
          lang: this.locale
        },
        this.apiHeader
      ).then((response) => {
        this.updateKitchen(response.data)
      }).catch(function (error) {
        if (error.response) {
          // Request made and server responded
        } else if (error.request) {
          // The request was made but no response was received
        } else {
          // Something happened in setting up the request that triggered an Error
        }
      })
    },
    findMatchingAccociatedProduct: function (products, addOnFeatures) {
      return products.filter(obj => {
        if (obj.features.find(feature => feature.id === addOnFeatures[0])) {
          return true
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.c-assortment {
  &__result {
    padding-bottom: 40px;

    &:not(:last-child) {
      margin-bottom: 80px;
    }

    &:last-child {
      margin-bottom: 50px;
    }
  }
}

.c-step__button-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 32px;
  margin-bottom: 32px;
}

.c-result {
  padding-bottom: 40px;
  margin-bottom: 50px;

  &__feature-description {
    color: #fff;
    margin-bottom: 40px;
  }

  &__product {
    margin-bottom: 56px;

    &-bundle {
      border: 4px solid $brand;
      padding: 40px;
      margin-bottom: 40px;
      position: relative;

      &::before {
        content: 'Bundle';
        position: absolute;
        top: -15px;
        left: 40px;
        background-color: $brand;
        border-left: 4px solid $brand;
        border-right: 4px solid $brand;
        padding-left: 16px;
        padding-right: 16px;
        color: #fff;
        line-height: 28px;
      }

      &:lang(se) {
        &::before {
          content: 'Paket';
        }
      }

      &:lang(en) {
        &::before {
          content: 'Bundle';
        }
      }
    }

    &-bundle {
      &__products {
        display: flex;
        align-items: center;
        align-items: stretch;

        @include mq($screen-m, max) {
          flex-direction: column;
        }

        .c-child-product {
          @include mq($screen-m, max) {
            max-width: 100%;
          }
        }
      }
    }

    &-bundle-plus {
      color: $brand;
      margin-left: 20px;
      margin-right: 20px;
      min-width: 40px;
      align-self: center;

      @include mq($screen-m, max) {
        margin-top: 20px;
        margin-bottom: 20px;
      }
    }
  }
}

.c-alternatives {
  &__section {
    padding-bottom: 40px;

    &:not(:last-child) {
      margin-bottom: 80px;
    }

    &:last-child {
      margin-bottom: 50px;
    }
  }

  &__extras-heading {
    @include t5;
    color: #fff;
    margin-bottom: 24px;
  }

  &__main-feature {
    margin-bottom: 8px;
  }
}

.c-feature-heading {
  @include t5;
  font-weight: bold;
  margin-bottom: 24px;
  margin-top: 42px;
  margin-bottom: 36px;
  color: $black;
}
</style>
