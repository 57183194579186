<template>
    <div>
        <v-header />
        <router-view :key="$route.fullPath" />
    </div>
</template>

<script src="./Category.js"></script>

<style lang="scss" scoped>
.c-parent-cat {
    &__heading1 {
        @include t1;
        color: $brand;
        margin-bottom: 24px;
    }

    &__description {
        @include t6;
        color: $brand;
        margin-bottom: 32px;
    }

    &__head {
        margin-bottom: 120px;

        @include mq($screen-l, min) {
            max-width: 80%;
        }
    }
}
</style>
