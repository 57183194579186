<template>
  <div
    v-if="content && !hideFeature"
    class="c-basic-feature"
    :availableProductSizes="uniqueProductSizes ? uniqueProductSizes : ''"
  >
    <div class="c-basic-feature__img-container">
      <v-slider
        v-if="images"
        :images="images"
        :mediaHeight="260"
        :mediaHeightMobile="150"
      />
    </div>
    <div class="c-basic-feature__text-container">
      <p class="c-basic-feature__heading">
        {{ heading }}
      </p>
      <div
        class="c-basic-feature__content"
        v-html="content"
      ></div>
    </div>
  </div>
</template>
<script>
import Slider from '@/components/ImageSlider/ImageSlider.vue'

export default {
  name: 'BasicFeature',
  props: {
    heading: String,
    content: String,
    images: Array,
    hideFeature: Boolean,
    uniqueProductSizes: Array
  },
  components: {
    'v-slider': Slider
  },
  methods: {
    getUniqueProductSizes: function (products) {
      const uniqueSizes = []
      products.forEach(product => uniqueSizes.push(product.size))
      return uniqueSizes.filter(function (item, pos, self) {
        return self.indexOf(item) === pos
      }).sort((a, b) => (a > b) ? 1 : ((b > a) ? -1 : 0))
    }
  },
  mounted () {
  }
}

</script>

<style lang="scss" scoped>
.c-basic-feature {
  @apply bg-brand-warm-2;

  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;

  &__text-container {
    height: 100%;
    padding: 20px;
    color: $black;
  }

  &__heading {
    @include t6;

    font-size: 1.675rem;
    line-height: 2.1rem;
    margin-bottom: 0.5rem;
  }

  &__content {
    //
  }

  &__img-container {
    display: flex;

    .imageslider img {
      height: auto;
    }
  }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @include mq($screen-m, max) {
    margin-bottom: 20px;
    min-width: 45%;
    width: 100%
  }

  @include mq($screen-s, max) {
    margin-bottom: 20px;
    min-width: 100%;
    width: 100%
  }
}
</style>
