<template>
  <div class="c-step__head">
    <div class="c-step__content-wrapper">
      <h1 class="c-step__heading text-brand-primary text-3.5xl lg:text-4.5xl font-heading">
        {{ title }}
      </h1>

      <div
        v-if="text"
        class="c-step__description"
      >
        <div
          class="text-black"
          v-html="text"
        ></div>
      </div>

      <div
        v-if="addedProduct.length"
        class="c-step__description"
      >
        <div class="c-step__addedProduct-badge">
          <p
            v-for="product in addedProduct"
            :key="product"
          >
            <v-icon
              placement="before"
              type="check"
              size="24"
              class="c-progressbar__checked"
            />
            {{ product.title }} {{ translations[locale].text.added_to_your_wishlist }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from '@/components/Icon/Icon.vue'
import { ENGLISH_TRANSLATIONS } from '@/translations/en'
import { SWEDISH_TRANSLATIONS } from '@/translations/se'
import { DANISH_TRANSLATIONS } from '@/translations/dk'
import { NORWEGIAN_TRANSLATIONS } from '@/translations/no'
import { FINNISH_TRANSLATIONS } from '@/translations/fi'

const translations = {
  en: ENGLISH_TRANSLATIONS,
  se: SWEDISH_TRANSLATIONS,
  dk: DANISH_TRANSLATIONS,
  no: NORWEGIAN_TRANSLATIONS,
  fi: FINNISH_TRANSLATIONS
}
export default {
  name: 'StepHead',
  components: {
    'v-icon': Icon
  },
  props: {
    stepCounter: Number,
    title: String,
    text: String,
    addedProduct: Array
  },
  data () {
    return {
      locale: this.$route.params.lang,
      translations
    }
  }
}
</script>

<style lang="scss" scoped>
.c-step {
  padding-bottom: 120px;
  position: relative;

  &__head {
    display: flex;
    margin-bottom: 1rem;

    .c-step__heading {
      margin-top: 18px;
    }
  }

  &__counter {
    @include t6;

    color: #fff;
    padding: 24px 32px;
    background: $stone-light;
    margin-right: 24px;
  }

  &__heading {
    margin-bottom: 24px;
  }

  &__description {
    color: $snow;
    margin-bottom: 24px;

    &--hint {
      @include s2;

      margin-bottom: 40px;
      display: flex;
      align-items: center;
    }

    p:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  &__basics {
    display: flex;
    gap: 8px;
    margin-bottom: 80px;

    &--alternatives {
      margin-bottom: 40px;
    }
  }

  &__button-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 32px;
    margin-bottom: 32px;
  }

  &__back-link {
    @include t6;
    color: $brand-green-6;
    font-weight: 700;
    display: inline-flex;
    align-items: center;
    transition: $default-transition;
  }

  &__addedProduct-badge {
    background-color: $brand-green-6;
    padding: 10px 20px 10px 20px;
    margin-right: 10px;
    position: relative;

    .c-icon {
      vertical-align: middle;
      position: relative;
    }
  }

  @include mq($screen-l, min) {
    &__head {
      max-width: 80%;
    }
  }

  @include mq($screen-m, max) {
    padding-bottom: 80px;

    &__heading {
      margin-bottom: 16px;
    }

    &__basics {
      flex-direction: column;
      margin-bottom: 40px;
    }

    &__head {
      &--step {
        margin-bottom: 40px;

        .c-alternatives__heading {
          margin-top: 0;
        }
      }
    }

    &__button-wrapper {
      margin-top: 40px;
    }

    &__counter {
      padding: 16px;
    }

    &__description {
      margin-bottom: 16px;

      &--hint {
        margin-bottom: 24px;
      }
    }
  }
}

.c-results__result {
  padding-bottom: 40px;

  &:not(:last-child) {
    margin-bottom: 80px;
  }

  &:last-child {
    margin-bottom: 50px;
  }
}

.c-result {
  padding-bottom: 40px;
  margin-bottom: 50px;

  &__feature-description {
    color: #fff;
    margin-bottom: 40px;
  }

  &__product {
    margin-bottom: 56px;

    &-bundle {
      border: 4px solid $brand-green-6;
      padding: 40px;
      position: relative;

      &::before {
        content: 'Bundle';
        position: absolute;
        top: -15px;
        left: 40px;
        background-color: $brand-green-6;
        border-left: 4px solid $black;
        border-right: 4px solid $black;
        padding-left: 16px;
        padding-right: 16px;
        color: #fff;
        line-height: 28px;
      }
    }

    &-bundle {
      &__products {
        display: flex;
        align-items: center;
        align-items: stretch;
      }
    }

    &-bundle-plus {
      color: $brand-green-6;
      margin-left: 20px;
      margin-right: 20px;
      min-width: 40px;
      align-self: center;
    }
  }
}

.c-alternatives {
  &__section {
    padding-bottom: 40px;
    border-bottom: 4px solid $brand-green-6;

    &:not(:last-child) {
      margin-bottom: 80px;
    }

    &:last-child {
      margin-bottom: 50px;
    }
  }

  &__extras-heading {
    @include t5;

    color: $black;
    margin-bottom: 24px;
  }

  &__main-feature {
    margin-bottom: 8px;
  }
}

.c-features {
  &__head {
    display: flex;
    margin-bottom: 56px;
  }

  padding-bottom: 40px;
  margin-bottom: 1rem;
}

.c-feature-heading {
  @include t5;

  font-weight: 700;
  margin-bottom: 24px;
  margin-top: 24px;
  color: $brand;
}
</style>
