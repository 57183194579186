<template>
  <a
    :href="url"
    class="c-link"
    :target="newTab ? '_blank' : '_self'"
    :rel="newTab ? 'noopener' : ''"
    @mouseenter="hover = true"
    @mouseleave="hover = false"
  >
    <span
      v-if="!iconLeft"
      :class="classes"
    >{{ text }}</span>

    <transition
      name=""
      mode="out-in"
    >
      <v-icon
        v-if="!iconHover || (icon && !hover)"
        :class="iconClasses"
        class="text-brand-primary"
        :placement="!iconLeft ? 'after' : 'before'"
        :type="icon"
        :size="iconSize"
        data-html2canvas-ignore
      ></v-icon>

      <v-icon
        v-else-if="hover && iconHover"
        :class="iconClasses"
        class="text-brand-primary"
        placement="after"
        :type="iconHover"
        :size="iconSizeHover"
        data-html2canvas-ignore
      ></v-icon>
    </transition>

    <span
      v-if="iconLeft"
      :class="classes"
    >{{ text }}</span>
  </a>
</template>
<script>
import Icon from '@/components/Icon/Icon.vue'

export default {
  name: 'Link',
  props: {
    text: String,
    url: String,
    newTab: Boolean,
    classes: String,
    iconClasses: String,
    iconLeft: {
      type: Boolean,
      default: false
    },
    icon: String,
    iconHover: String,
    iconSize: String,
    iconSizeHover: String,
    sizeValidator: (iconSize) => ['12', '16', '24'].includes(iconSize)
  },
  components: {
    'v-icon': Icon
  },
  data () {
    return {
      hover: false
    }
  }
}
</script>

<style lang="scss" scoped>
.c-link {
  color: $black;
  font-weight: 700;
  display: inline-flex;
  align-items: center;
  transition: $default-transition;

  &:hover {
    color: $brand;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease-in-out;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
