<template>
  <div
    v-if="content && !hideFeature"
    class="c-choosable-feature"
    :class="imgPosition == 'left' ? 'c-choosable-feature--img-left' : ''"
    :availableProductSizes="uniqueProductSizes ? uniqueProductSizes : ''"
  >
    <div class="c-choosable-feature__text-container">
      <div class="c-choosable-feature__text">
        <div class="c-choosable-feature__text-top">
          <div
            class="c-choosable-feature__number"
            v-if="number"
          >
            {{ number }}
          </div>
          <h3 class="c-choosable-feature__heading">
            {{ title }}
          </h3>
          <!-- <div v-if="!sizeOnImage && uniqueProductSizes">
             <span class="c-choosable-feature__size"  v-for="size in uniqueProductSizes" :key="size">
                 {{ size }} cm
             </span>
         </div> -->
          <div
            class="c-choosable-feature__content"
            v-html="content"
          ></div>
        </div>
        <div class="c-choosable-feature__text-bottom">
          <div v-if="checkboxLabel">
            <label
              class="c-choosable-feature__checkbox-label"
              v-if="chosenFeatures.includes(featureId)"
            >
              <v-icon
                v-if="chosenFeatures.includes(featureId)"
                class="c-choosable-feature__checkbox-icon"
                type="check"
                size="24"
              />
              <input
                type="checkbox"
                class="c-choosable-feature__checkbox"
                :checked="chosenFeatures.includes(featureId) ? 'checked' : false"
                :data-feature-id="featureId"
                @click="updateCheckedFeatures(featureId, highlightedFeatures, uniqueProductSizes, allowSizeRestrictionChange)"
              >
              {{ checkboxLabel }}
            </label>
            <label
              class="c-choosable-feature__checkbox-label"
              v-else-if="addOnFeatures.includes(featureId)"
            >
              <v-icon
                v-if="addOnFeatures.includes(featureId)"
                class="c-choosable-feature__checkbox-icon"
                type="check"
                size="24"
              />
              <input
                type="checkbox"
                class="c-choosable-feature__checkbox"
                :checked="addOnFeatures.includes(featureId) ? 'checked' : false"
                :data-feature-id="featureId"
                @click="updateCheckedFeatures(featureId, highlightedFeatures, uniqueProductSizes, allowSizeRestrictionChange)"
              >
              {{ checkboxLabel }}
            </label>
            <label
              class="c-choosable-feature__checkbox-label"
              v-else
            >
              <v-icon
                class="c-choosable-feature__checkbox-icon"
                type="check"
                size="24"
              />
              <input
                type="checkbox"
                class="c-choosable-feature__checkbox"
                :data-feature-id="featureId"
                @click="updateCheckedFeatures(featureId, highlightedFeatures, uniqueProductSizes, allowSizeRestrictionChange)"
              >
              {{ checkboxLabel }}
            </label>
          </div>
        </div>
      </div>
    </div>

    <v-slider
      class="c-choosable-feature__img-container"
      v-if="images"
      :images="images"
    />

    <div
      v-if="sizeOnImage"
      data-html2canvas-ignore
    > <!-- && !sizes === 'null' -->
      <!-- <div v-if="sizes" class="c-choosable-feature__size c-choosable-feature__size--on-image">{{ sizes }} cm</div> -->
      <span
        class="c-product__energy-label"
        v-if="getEnergyLabelIcon(content)"
        data-html2canvas-ignore
      >
        <img
          :src="require('@/assets/images/labels/' + getEnergyLabelIcon(content))"
          alt=""
          :crossorigin="$store.state.env !== 'production' ? null : '*'"
        >
      </span>
    </div>
  </div>
</template>

<script>
import Icon from '@/components/Icon/Icon.vue'
import Slider from '@/components/ImageSlider/ImageSlider.vue'
import { mapMutations, mapGetters, mapActions } from 'vuex'

import { getEnergyLabelIcon } from '@/labels'

export default {
  name: 'ChoosableFeature',
  data () {
    return {
      height: null,
      sortedSizes: this.sortedSizes,
      checkedFeatures: [],
      featureInput: ''
    }
  },
  components: {
    'v-icon': Icon,
    'v-slider': Slider
  },
  props: {
    imgPosition: String,
    validator: (imgPosition) => ['left', 'right'].includes(imgPosition),
    title: String,
    checkboxLabel: String,
    images: Array,
    number: Number,
    content: String,
    featureId: Number,
    checked: Boolean,
    sizeOnImage: Boolean,
    hideFeature: Boolean,
    uniqueProductSizes: Array,
    allowSizeRestrictionChange: Boolean,
    highlightedFeatures: Array
  },
  computed: {
    ...mapGetters([
      'chosenFeatures',
      'addOnFeatures',
      'stepInfo'])
  },
  methods: {
    ...mapMutations([
      'choices',
      'updateChoices',
      'updateKitchen',
      'clearChoices',
      'addChosenFeatureToCoices',
      'removeChosenFeatureFromChoices',
      'removeChosenFeaturesFromChoices',
      'addChosenFeaturesToChoices',
      'removeSizeRestrictions',
      'addSizeRestrictions',
      'addAddOnFeatureToChoices',
      'removeAddOnFeatureFromChoices'
    ]),
    ...mapActions([
      'removeChosenFeatures',
      'addChosenFeatures'
    ]),
    /* setHeight () {
      const initialHeight = this.$refs.feature.offsetHeight + 'px'
      if (this.$refs.featureImage) {
        this.$refs.featureImage.style.height = initialHeight
      }
      this.height = initialHeight
    }, */
    getCheckedFeatures: function (featureId) {
      if (this.chosenFeatures.includes(featureId)) {
        return true
      } else {
        return false
      }
    },
    updateCheckedFeatures: function (featureId, highlightedFeatures, sizes, allowSizeRestrictionChange) {
      const featureCollection = highlightedFeatures
      highlightedFeatures.push(featureId)
      // let itemsProcessed = 0
      if (this.stepInfo.show_addon_products === 1) {
        if (this.addOnFeatures.includes(featureId)) {
          this.removeAddOnFeatureFromChoices(featureId)
          if (allowSizeRestrictionChange) {
            this.removeSizeRestrictions(sizes)
          }
        } else {
          this.addAddOnFeatureToChoices(featureId)
          if (allowSizeRestrictionChange) {
            this.addSizeRestrictions(sizes)
          }
        }
      } else {
        if (this.chosenFeatures.includes(featureId)) {
          // this.removeChosenFeaturesFromChoices(featureCollection)
          this.removeChosenFeatures(featureCollection)
          /* featureCollection.forEach(el => {
            this.removeChosenFeatureFromChoices(el)
            itemsProcessed++
            if (itemsProcessed === highlightedFeatures.length) {
              // this.removeChosenFeatureFromChoices(featureId)
            }
          }) */
          if (allowSizeRestrictionChange) {
            this.removeSizeRestrictions(sizes)
          }
        } else {
          // this.addChosenFeaturesToChoices(featureCollection)
          this.addChosenFeatures(featureCollection)
          /* featureCollection.forEach(el => {
            this.addChosenFeatureToCoices(el)
            itemsProcessed++
            if (itemsProcessed === highlightedFeatures.length) {
              // this.addChosenFeatureToCoices(featureId)
            }
          }) */
          if (allowSizeRestrictionChange) {
            this.addSizeRestrictions(sizes)
          }
        }
      }
    },
    getEnergyLabelIcon
  },
  mounted () {
    // this.sortedSizes = this.sizes.sort((a, b) => (a > b) ? 1 : ((b > a) ? -1 : 0))
  }
}
</script>

<style lang="scss" scoped>
.c-choosable-feature {
  display: flex;
  margin-bottom: 40px;

  &:nth-child(even) {
    .c-choosable-feature__img {
      order: 1;
    }

    .c-choosable-feature__text-container {
      order: 2;
    }
  }

  &__text-container {
    flex: 1;
    display: block;
    box-sizing: border-box;
    background-color: $brand-warm-2;
    position: relative;
    width: 50%;

    @include mq($screen-m, max) {
      width: 100%;
    }
  }

  &__text {
    padding: 32px 32px 32px 64px;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;

    &-top {
      margin-bottom: 16px;
    }
  }

  &__number,
  &__size {
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  &__number {
    position: absolute;
    left: 0;
    top: 32px;
    height: 48px;
    width: 48px;
    background-color: $brand;
    color: #fff;
  }

  &__size {
    @include s2;
    font-weight: 700;
    padding: 5px 8px 3px;
    background-color: $orange;

    &:not(:last-child) {
      margin-right: 8px;
    }

    margin-bottom: 8px;

    &--on-image {
      position: absolute;
      bottom: auto;
      top: 32px;
    }
  }

  &__content {

    &:deep(p),
    &:deep(ul) {
      @apply my-6;
      color: $deep-blue-2;
    }

    &:deep(li) {
      color: $deep-blue-2;
      font-size: 16px;
      line-height: 18px;
      padding-left: 32px;
      position: relative;

      &:not(:last-child) {
        margin-bottom: 8px;
      }

      &::before {
        content: '';
        height: 16px;
        width: 16px;
        display: block;
        position: absolute;
        left: 0;
        top: 16px;
        transform: translateY(-50%);
        background-image: url("data:image/svg+xml,%0A%3Csvg width='16' height='14' viewBox='0 0 16 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.3438 0.96875L16 2.625L5.59375 13.0312L0 7.4375L1.65625 5.78125L5.25 8.875L14.3438 0.96875Z' fill='%23aeae6e'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
      }
    }
  }

  &__checkbox-label {
    cursor: pointer;
    position: relative;
    display: inline-flex;
    align-items: center;
    font-weight: 700;
    color: $black;
  }

  &__checkbox-icon {
    position: absolute;
    z-index: 3;
    top: 50%;
    transform: translateY(-50%);
    left: 3px;
    margin-left: 0;
    color: #fff;
  }

  &__checkbox {
    cursor: pointer;
    -webkit-appearance: none;
    background-color: #fff;
    display: inline-block;
    border: 1px solid $brand-warm-4;
    padding: 9px;
    margin-right: 12px;
    width: 30px;
    height: 30px;
    min-width: 30px;

    &:focus {
      outline: none;
    }

    &:checked {
      background-color: $brand;
      border-color: $brand;
    }

    @include mq($screen-m, max) {
      height: 22px;
      width: 22px;
    }
  }

  &__img-container {
    flex: 1;
    position: relative;
    display: block;
    width: 50%;

    @include mq($screen-m, max) {
      width: 100%;
    }
  }

  &__img {
    width: 100%;
    height: 100%;
    min-height: 350px;
    object-fit: cover;
    display: block;
  }

  &__heading {
    @apply my-2;
    @include t5;
    color: $deep-blue-2;
  }

  @include mq($screen-m, max) {
    max-width: 50%;
    margin-bottom: 20px;

    &__img {
      order: 1;
    }

    &__text-container {
      order: 2;
    }

    &__img-container {
      flex: auto;
    }

    &__checkbox {
      height: 22px;
      width: 22px;
      min-width: 22px;
    }
  }

  @include mq($screen-m, max) {
    max-width: 100%;
    margin-bottom: 20px;
    flex-direction: column;
  }
}
</style>
