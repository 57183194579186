<template>
  <div v-if="categoryinfo">
    <div class="l-container">
      <div class="max-w-screen-lg mb-28">
        <h1
          v-if="categoryinfo.intro_title"
          class="c-parent-cat__heading1"
        >
          {{ categoryinfo.intro_title }}
        </h1>

        <div
          v-if="categoryinfo.text"
          class="c-parent-cat__description"
          v-html="categoryinfo.text"
        ></div>

        <router-link
          v-if="categoryinfo.link_text && categoryinfo.link_url"
          class="c-btn c-btn--primary"
          :to="{ path: `/${locale}${categoryinfo.link_url}` }"
        >
          <v-button
            :text="categoryinfo.link_text"
            variation="primary"
          />
        </router-link>
      </div>

      <transition name="fade">
        <div
          class="lds-roller-wrapper"
          v-if="loading"
        >
          <div class="lds-roller">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </transition>
    </div>

    <v-media-footer
      v-if="!loading"
      :videoType="categoryinfo.video_url ? 'youtube' : categoryinfo.video_upload ? 'html' : null"
      :videoUrl="categoryinfo.video_url"
      :videoName="categoryinfo.video_upload"
      :imageName="categoryinfo.media_files[0].filename"
    />
  </div>
</template>

<script>
import Button from '@/components/Button/Button.vue'
import MediaFooter from '@/components/MediaFooter/MediaFooter.vue'
import { mapGetters, mapActions, mapMutations } from 'vuex'

export default {
  Name: 'Category Start',
  components: {
    'v-button': Button,
    'v-media-footer': MediaFooter
  },
  data () {
    return {
      route: this.$route.path,
      locale: this.$route.params.lang,
      loading: true
    }
  },
  async created () {
    await this.loadCategoryContent()
  },
  computed: {
    ...mapGetters([
      'categoryinfo',
      'addedProduct',
      'categorieslist'
    ])
  },
  methods: {
    ...mapActions(['loadCategory', 'loadCategoryProducts']),
    ...mapMutations([
      'clearChoices',
      'clearDesignChoices',
      'clearAddedProduct',
      'clearSizeRestrictions',
      'clearAddOnFeatures'
    ]),
    async loadCategoryContent () {
      this.loading = true
      this.slug = this.$route.params.slug

      await this.loadCategory(this.slug)
      await this.loadCategoryProducts(this.categoryinfo.id)

      this.loading = false
      this.clearChoices()
      this.clearDesignChoices()
      this.clearSizeRestrictions()
      this.clearAddedProduct()
      this.clearAddOnFeatures()
    }
  }
}

</script>

<style lang="scss" scoped>
.c-parent-cat__heading1 {
  @apply mb-10;
  @include t2;
}

.c-parent-cat__description {
  @apply mb-10;
}
</style>
