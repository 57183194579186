export const DANISH_TRANSLATIONS = {
  text: {
    i_want: 'Jeg vil have',
    about_your_new: 'Om dit nye',
    you_also_get: 'Du får også',
    back: 'Tilbage',
    skip: 'Spring dette trin over',
    itemscount: 'varer i Mit Køkken',
    removeitem: 'Fjerne varen',
    add_to_my_kitchen: 'Tilføj til My Kitchen',
    send: 'sende',
    enteremail: 'Indtast e-mail',
    dont_have_time_right_now: 'Har du ikke tid lige nu?',
    remind_me_later: 'Mind mig om det senere',
    added_to_your_wishlist: 'blev tilføjet på din ønskeseddel.',
    added_to_my_kitchen: 'tilføjet til My Kitchen.',
    here_are_all_the_appliances_that_you_want_in_your_next_dream_kithcen: 'Her er alle de produkter, du gerne vil have i dit drømmekøkken',
    unique_link_for_your_kitchen: 'Unikt link til dit køkken',
    send_your_kitchen_to_your_email: 'Send dit køkken til din e-mail',
    pdf_successfully_sent_to_your_email: 'PDF blev sendt til din e-mail!',
    copy: 'Kopier',
    download_package_as_pdf: 'Download pakke som PDF',
    print: 'Print',
    my_kitchen: 'My Kitchen',
    insert_your_email: 'Udfyld din e-mailadresse',
    send_reminder: 'Send en påmindelse',
    email_required: 'E-mail-adresse påkrævet',
    valid_email_required: 'Gyldig e-mailadresse er påkrævet',
    reminder_is_sent_to_your_email: 'Påmindelse sendes til din e-mailadresse!',
    close: 'Luk',
    need_a_different_size: 'Har du brug for en anden størrelse?',
    download_detail_product_pdf: 'Download detaljeret produktblad i PDF',
    bundle: 'Hvidevarepakker',
    or_do_you_need_the_little_sister_with_most_of_above_functions: 'Eller har du brug en basismodel med de fleste af ovenstående funktioner, men ikke så sofistikeret?',
    features: 'funktioner',
    continue_to_next_step: 'Fortsæt til næste trin',
    your_kitchen_is_empty: 'My Kitchen er tom, tilføj nogle produkter',
    downloading: 'Download',
    get_back_to_where_you_started: 'Gå tilbage, hvor hvor du startede køkkenguiden',
    info_about_concept: 'Info om studioLine konceptet',
    accept: 'Accept',
    decline: 'Afvis',
    please_accept: 'Acceptér venligst cookie-samtykke for at afspille denne video',
    cookies_text: 'Hvis du klikker på “Accept”, giver du os lov til at registrere din bruger adfærd på denne hjemmeside. Dette give os mulighed for at forbedre vores hjemmeside og tilpasse vores markedsføring til dig. Hvis du klikker ”Afvis” vil vi kun bruge sessions cookies til at forbedre brugervenligheden og til statistik.',
    start_over: 'Start forfra'
  }
}
