export const NORWEGIAN_TRANSLATIONS = {
  text: {
    i_want: 'Jeg ønsker',
    about_your_new: 'Om ditt nye',
    you_also_get: 'Les mer under her',
    back: 'Tilbake',
    skip: 'Hopp over dette trinnet',
    itemscount: 'varer i Mitt kjøkken',
    removeitem: 'Fjerne varen',
    add_to_my_kitchen: 'Legg til på My Kitchen',
    send: 'send',
    enteremail: 'Skriv inn e-post',
    dont_have_time_right_now: 'Har du ikke tid akkurat nå?',
    remind_me_later: 'Minn meg på det senere',
    added_to_your_wishlist: 'ble lagt til i ønskelisten din.',
    added_to_my_kitchen: 'lagt til på My Kitchen.',
    here_are_all_the_appliances_that_you_want_in_your_next_dream_kithcen: 'Her er alle hvitevarene du ønsker deg til ditt nye kjøkken',
    unique_link_for_your_kitchen: 'Unik lenke til kjøkkenet ditt',
    send_your_kitchen_to_your_email: 'Send kjøkkenet ditt til din e-post',
    pdf_successfully_sent_to_your_email: 'PDF ble sendt til e-posten din!',
    copy: 'Kopiere',
    download_package_as_pdf: 'Last ned pakke som PDF',
    print: 'Skriv ut',
    my_kitchen: 'My Kitchen',
    insert_your_email: 'Fyll inn e-postadressen din',
    send_reminder: 'Send påminnelse',
    email_required: 'E-postadresse kreves',
    valid_email_required: 'Gyldig e-postadresse kreves',
    reminder_is_sent_to_your_email: 'Påminnelse sendes til din e-postadresse!',
    close: 'Lukk',
    need_a_different_size: 'Trenger du en annen størrelse?',
    download_detail_product_pdf: 'Last ned detaljert produktblad i PDF',
    bundle: 'Pakke',
    or_do_you_need_the_little_sister_with_most_of_above_functions: 'Eller trenger du lillebroren med de fleste av funksjonene ovenfor, men som ikke er like sofistikerte?',
    features: 'funksjoner',
    continue_to_next_step: 'Fortsett til neste trinn',
    your_kitchen_is_empty: 'My Kitchen er tomt, legg til noen produkter',
    downloading: 'Laster ned',
    get_back_to_where_you_started: 'Gå tilbake til der du startet kjøkkenguiden',
    info_about_concept: 'Info om studioLine-konseptet',
    accept: 'Godta',
    decline: 'Avslå',
    please_accept: 'Godta samtykke fra informasjonskapsler for å spille av denne videoen',
    cookies_text: 'Hvis du klikker på "Godta", lar du oss registrere bruksatferden din på denne nettsiden. Dette lar oss forbedre nettsiden vår og tilpasse annonsering for deg. Hvis du klikker på "avslå", vil vi kun bruke session cookies for å forbedre brukervennligheten og måle statistisk rekkevidde.',
    start_over: 'Start på nytt'
  }
}
