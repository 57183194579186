<template>
  <div>
    <header class="c-header u-flex">
      <v-cookie-popup />
      <div class="c-header__inner l-container">
        <router-link :to="{ path: `/${locale}/` }">
          <img
            class="w-44 md:w-60"
            src='@/assets/images/logo.svg'
            :crossorigin="$store.state.env !== 'production' ? null : '*'"
          />
        </router-link>

        <div
          class="c-header__right"
          data-html2canvas-ignore
        >
          <v-language-switcher />

          <div class="flex flex-wrap justify-end items-center mt-5">
            <a
              :href="'/studioLine_Concept_' + locale + '.pdf'"
              target="_blank"
              class="mb-4 md:mb-0"
            >
              <v-tooltip
                :text="translations[locale].text.info_about_concept"
                position="left"
              >
                <v-icon
                  class="text-brand-primary"
                  type="info"
                  size="24"
                />
                <span class="ml-2 md:hidden text-brand-primary">{{
                  translations[locale].text.info_about_concept
                }}</span>
              </v-tooltip>
            </a>

            <v-itemscount class="ml-5" />
          </div>
        </div>
      </div>
    </header>

    <div
      class="l-container no-print"
      data-html2canvas-ignore
    >
      <v-progressbar />
    </div>
  </div>
</template>

<script>
import CookiePopup from '@/components/CookiePopup.vue'
import ProgressBar from '@/components/ProgressBar/ProgressBar.vue'
import LanguageSwitcher from '@/components/LanguageSwitcher/LanguageSwitcher.vue'
import ItemsCount from '@/components/ItemsCount/ItemsCount.vue'
import Icon from '@/components/Icon/Icon.vue'
import Tooltip from '@/components/Tooltip.vue'

import { ENGLISH_TRANSLATIONS } from '@/translations/en'
import { SWEDISH_TRANSLATIONS } from '@/translations/se'
import { DANISH_TRANSLATIONS } from '@/translations/dk'
import { NORWEGIAN_TRANSLATIONS } from '@/translations/no'
import { FINNISH_TRANSLATIONS } from '@/translations/fi'

const translations = {
  en: ENGLISH_TRANSLATIONS,
  se: SWEDISH_TRANSLATIONS,
  dk: DANISH_TRANSLATIONS,
  no: NORWEGIAN_TRANSLATIONS,
  fi: FINNISH_TRANSLATIONS
}

export default {
  name: 'Header',
  data () {
    return {
      dropdownOpen: false,
      mobile: window.innerWidth <= 750,
      spritePath: require('@/assets/images/sprite.svg'),
      locale: this.$route.params.lang,
      translations
    }
  },
  created () {
    addEventListener('resize', () => {
      this.mobile = innerWidth <= 750
    })
  },
  props: {
    homepage: Boolean
  },
  components: {
    'v-cookie-popup': CookiePopup,
    'v-language-switcher': LanguageSwitcher,
    'v-progressbar': ProgressBar,
    'v-itemscount': ItemsCount,
    'v-icon': Icon,
    'v-tooltip': Tooltip
  }
}

</script>

<style lang="scss" scoped>
.c-header {
  @apply flex justify-between py-8 border-solid border-brand-primary;

  border-top-width: 12px;

  @include mq($screen-m, max) {
    @apply py-6;

    border-top-width: 8px;
  }

  @include mq($screen-s, max) {
    border-top-width: 5px;
  }

  &__right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;

    @include mq($screen-s, max) {
      justify-content: center;
    }
  }

  &__language-button {
    @include s2;
    color: $brand-warm-1;
    background-color: transparent;
    position: relative;
    transition: $default-transition;

    &:hover,
    &.is-open {
      color: $brand;
    }
  }

  &__language-switcher {
    position: relative;
  }

  &__languages {
    position: absolute;
    right: 0;
    z-index: 2;
    margin-top: 10px;
    width: 150px;
    background-color: $brand;
  }

  &__language {
    transition: $default-transition;
    display: block;
    padding: 10px;
    border-bottom: 1px solid $brand-warm-1;
    font-size: 14px;
    font-weight: normal;
    text-decoration: none;
    color: $black;

    &:hover {
      color: $brand;
    }
  }

  &__inner {
    display: flex;
    justify-content: space-between;
  }
}

@media print {

  .no-print,
  .no-print * {
    display: none !important;
  }
}
</style>
