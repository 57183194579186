<template>
  <div class="c-language-switcher">
    <v-button
      href=""
      class="c-language-switcher__button c-link"
      :data-lang="locale"
      :key="languages"
      :class="{ 'is-open': dropdownOpen }"
      :text="languages[locale]"
      @click.prevent="dropdownOpen = !dropdownOpen"
    >
      <v-icon
        placement="after"
        type="chevron-down"
        size="12"
      ></v-icon>
    </v-button>

    <ul
      class="c-language-switcher__languages"
      v-show="dropdownOpen"
    >
      <li
        v-for="(option, index) in options"
        :key="index"
      >
        <a
          class="c-language-switcher__language"
          @click.prevent="updateOption(option, index), changeCategoriesListLang(index)"
        >
          {{ option }}
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import Icon from '@/components/Icon/Icon.vue'
import Button from '@/components/Button/Button.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'LanguageSwitcher',
  components: {
    'v-icon': Icon,
    'v-button': Button
  },
  data () {
    return {
      route: this.$route,
      dropdownOpen: false,
      selectedOption: '',
      options: {
        en: 'English',
        se: 'Svenska',
        dk: 'Dansk',
        no: 'Norsk',
        fi: 'Suomi'
      },
      locale: this.$route.params.lang
    }
  },
  watch: {
    async $route () {
      this.selectedOption = this.$route.params.lang
    }
  },
  methods: {
    ...mapActions(['changeCategoriesListLang', 'loadCategory', 'loadCategoryProducts']),
    async updateOption (option, index) {
      this.selectedOption = option
      this.dropdownOpen = false
      this.selectedLocale = index
      this.selectedLanguage = {}
      this.selectedLanguage[this.selectedLocale] = option
      this.$emit('updateOption', this.selectedOption)
      this.locale = index
      localStorage.setItem('locale', index)

      if (this.$route.params.slug && this.categoryinfo.id) {
        await this.loadCategory(this.$route.params.slug)
        await this.loadCategoryProducts(this.categoryinfo.id)
      }

      this.$router.push({
        name: this.$route.name,
        params: {
          lang: index,
          slug: this.$route.params.slug,
          stepId: this.$route.params.stepId ? this.$route.params.stepId : ''
        }
      })
    }
  },
  computed: {
    ...mapGetters([
      'languages',
      'categoryinfo'
    ])
  }
}

</script>

<style lang="scss" scoped>
.c-language-switcher {
  position: relative;

  .c-language-switcher__button {
    @apply text-black bg-brand-warm-1 lg:bg-transparent px-3 py-1 lg:px-0;

    position: relative;
    transition: $default-transition;

    &:hover,
    &.is-open {
      color: $brand;
    }
  }

  .c-language-switcher__languages {
    @apply border border-solid border-brand-warm-2;

    position: absolute;
    right: 0;
    z-index: 20;
    width: 150px;
    background-color: $brand-warm-1;
  }

  .c-language-switcher__language {
    @apply border-b border-solid border-brand-warm-2;

    transition: $default-transition;
    display: block;
    padding: 10px;
    font-weight: normal;
    text-decoration: none;
    color: $black;

    &:hover {
      color: $brand;
      cursor: pointer;
    }
  }

  li:last-child {
    .c-language-switcher__language {
      @apply border-none;
    }
  }
}
</style>
